import { render, staticRenderFns } from "./repair1.vue?vue&type=template&id=ffcd8a2a&scoped=true&"
import script from "./repair1.vue?vue&type=script&lang=js&"
export * from "./repair1.vue?vue&type=script&lang=js&"
import style0 from "./repair1.vue?vue&type=style&index=0&id=ffcd8a2a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffcd8a2a",
  null
  
)

export default component.exports